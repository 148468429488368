import React from "react";
import { Link } from "react-router-dom";
import styles from './Benefites.module.css';
import stress from '../assects/stressrelife.png';
import Energy from '../assects/energyboost.png';
import Heart from '../assects/hearthealth.png';
import skincare from '../assects/skincare.png';
import calm from '../assects/calm .png';

const Benefites = () => {
    return (
        <div className={styles.benefites}>
            {/* <h1>Our Benefits</h1> */}
            <div className={styles.benefitesbox}>
                <Link to="#stress-relief">
                    <button>
                        <div className={`${styles.innerbox} ${styles.box1}`}>
                            <h2>Stress Relief</h2>
                            <img src={stress} alt="Stress Relief" />
                        </div>
                    </button>
                </Link>

                <a href="">
                    <button>
                        <div className={`${styles.innerbox} ${styles.box2}`}>
                            <h2>Immunity & Energy</h2>
                            <img src={Energy} alt="Immunity & Energy" />
                        </div>
                    </button>
                </a>

                <a href="">
                    <button>
                        <div className={`${styles.innerbox} ${styles.box3}`}>
                            <h2>Digestion</h2>
                            <img src={Heart} alt="Digestion" />
                        </div>
                    </button>
                </a>

                <a href="">
                    <button>
                        <div className={`${styles.innerbox} ${styles.box4}`}>
                            <h2>Diabetic Support</h2>
                            <img src={calm} alt="Diabetic Support" />
                        </div>
                    </button>
                </a>

                <a href="">
                    <button>
                        <div className={`${styles.innerbox} ${styles.box5}`}>
                            <h2>Hair & Skin</h2>
                            <img src={skincare} alt="Hair & Skin" />
                        </div>
                    </button>
                </a>
            </div>
        </div>
    );
};

export default Benefites;
