// import React from "react"
// import styles from  './BestSeller.module.css'
// import Jaggery from '../assects/Jaggery.png'
// import Honeyorg from '../assects/Honeyorg.png'
// import Moringa from '../assects/moronga.jpeg'
// // import ashbottle from '../assects/ash-bottle.png'
// import ashbottle from '../assects/ashfinal.jpg'
// // import Moringacap from '../assects/moringacap.jpg'
// import Moringacap from '../assects/moringacapsules.webp'
// import { Container } from "react-bootstrap"


// const BestSeller = () => {
//     return(
//         <div className={styles.BestSellerContainer} >
//                 <div className={styles.BestSellerHeading}>
//                     <h2>Best Selling Organic Products</h2>
//                 </div>
//                 <div className={styles.bestSellerBox}>
//                     <div className={styles.subBox}>
//                         <img src={ashbottle} alt="ashwagandha" />
//                         <button><a>Ashwagandha</a></button>
//                         <button><a href="https://amzn.in/d/4boWpLq" >Buy Now</a></button>
//                     </div>

//                     <div className={styles.subBox}>
//                         <img src={Honeyorg} alt="Honey" />
//                         <button><a>Honey</a></button>
//                         <button><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></button>
//                     </div>

//                     <div className={styles.subBox}>
//                         <img src={Moringacap} alt="Tea" />
//                         <button><a>Moringa (Coming Soon)</a></button>
//                         <li className={styles.buybox}><a href="https://amzn.in/d/9jyntBY" >Buy Now</a></li>
//                     </div>

//                     {/* <div className={styles.subBox}>
//                         <img src={Moringa} alt="Tea" />
//                         <button><a>Moringa</a></button>
//                     </div> */}

//                     <div className={styles.subBox}>
//                         <img src={Jaggery} alt="Tea" />
//                         <button><a>Jaggery (coming soon)</a></button>
//                         {/* <li className={styles.buybox}><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li> */}
//                     </div>
//                 </div>
//             </div>

//     )
// }


// export default BestSeller;








// // import React from "react";
// // import styles from "./BestSeller.module.css";
// // import { Container } from "react-bootstrap";
// // // import Jaggery from "../assets/Jaggery.png";
// // import Jaggery from '../assects/Jaggery.png'
// // // import Honeyorg from "../assets/Honeyorg.png";
// // import Honeyorg from '../assects/Honeyorg.png'
// // // import Moringa from "../assets/moronga.jpeg";
// // import Moringa from '../assects/moronga.jpeg'
// // import ashbottle from '../assects/ashfinal.jpg'
// // const products = [
// //   { img: ashbottle, name: "Ashwagandha", link: "https://amzn.in/d/1CSBXT6" },
// //   { img: Honeyorg, name: "Honey", link: "https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
// //   { img: Moringa, name: "Moringa (Coming Soon)", link: null },
// //   { img: Jaggery, name: "Jaggery (Coming Soon)", link: null },
// // ];

// // const BestSeller = () => (
// //   <Container className={styles.BestSellerContainer}>
// //     <h2 className={styles.BestSellerHeading}>Best Selling Organic Products</h2>
// //     <div className={styles.bestSellerBox}>
// //       {products.map(({ img, name, link }, index) => (
// //         <div key={index} className={styles.subBox}>
// //           <img src={img} alt={name} />
// //           <button>{name}</button>
// //           {link && (
// //             <li className={styles.buybox}>
// //               <a href={link} target="_blank" rel="noopener noreferrer">Buy Now</a>
// //             </li>
// //           )}
// //         </div>
// //       ))}
// //     </div>
// //   </Container>
// // );

// // export default BestSeller;


import React from "react";
import styles from "./BestSeller.module.css";
import { Container } from "react-bootstrap";
import Jaggery from "../assects/Jaggery.png";
import Honeyorg from "../assects/Honeyorg.png";
import Moringa from "../assects/moronga.jpeg";
import ashbottle from "../assects/ashfinal.jpg";
import Moringacap from "../assects/moringacapsules.webp";

const products = [
  { img: ashbottle, name: "Ashwagandha", link: "https://amzn.in/d/1CSBXT6" },
  { img: Honeyorg, name: "Honey", link: "https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
  { img: Moringacap, name: "Moringa (Coming Soon)", link: "https://amzn.in/d/9jyntBY" },
  { img: Jaggery, name: "Jaggery (Coming Soon)", link: null },
];

const BestSeller = () => {
  return (
    <Container className={styles.BestSellerContainer}>
      <h2 className={styles.BestSellerHeading}>Best Selling Organic Products</h2>
      
      <div className={styles.slider}>
        <div className={styles.sliderTrack}>
          {products.concat(products).map(({ img, name, link }, index) => (
            <div key={index} className={styles.sliderItem}>
              <img src={img} alt={name} />
              <button>{name}</button>
              {link && (
                <li className={styles.buybox}>
                  <a href={link} target="_blank" rel="noopener noreferrer">Buy Now</a>
                </li>
              )}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default BestSeller;
