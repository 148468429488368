import React, { useRef, useState } from "react";
import "./Committed.css";

const CommittedTo = () => {
    const scrollContainer = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    // Handle touch/mouse drag to scroll manually
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainer.current.offsetLeft);
        setScrollLeft(scrollContainer.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainer.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust speed
        scrollContainer.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => setIsDragging(false);
    const handleMouseLeave = () => setIsDragging(false);

    return (
        <div id="services">
            <div className="committed">
                <div className="committed-h1">
                    <h1>Forest Wellness Is Committed To</h1>
                </div>

                <div 
                    className="containers" 
                    ref={scrollContainer} 
                    onMouseDown={handleMouseDown} 
                    onMouseMove={handleMouseMove} 
                    onMouseUp={handleMouseUp} 
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="box">
                        <h3>Absolute Quality</h3>
                        <div>
                            <p>Exquisite Natural Integrity, and Unparalleled Excellence are the cornerstones of our commitment at Nature's Essence Organic Products. With a deep-rooted passion for health and sustainability, we meticulously source and curate the finest organic products and herbs, ensuring that each item reflects the purity and potency of nature's bounty</p>
                        </div>
                    </div>

                    <div className="box">
                        <h3>Total Integrity</h3>
                        <div>
                            <p>We embody 'Total Integrity' in every aspect of our business. Our commitment to integrity-driven service is evident in our core values of honesty, transparency, and ethical practices. Honesty is the foundation of our interactions with customers, suppliers, and stakeholders. We believe in being upfront and truthful in all our dealings, from the sourcing of organic products and herbs to the information provided about our offerings.</p>
                        </div>
                    </div>

                    <div className="box">
                        <h3>Services To All</h3>
                        <div>
                            <p>Our ethos revolves around 'Services To All,' where we strive to deliver the best in every aspect of our operations. Our commitment is rooted in providing unmatched quality, reliability, and customer satisfaction to all our patrons. We meticulously source the finest organic products and herbs, ensuring that each item upholds the highest standards of purity and potency.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommittedTo;
