// import React from "react";
import './Video.css'
import video1 from '../assects/honeyreel2.mp4';
import video3 from '../assects/ashreel1.mp4';
import video4 from '../assects/honeyreel1.mp4';
import video5 from '../assects/Reel1.mp4';
// // import video6 from './N-video6.MOV';
// import video7 from './N-video7.MOV';
// import video8 from './N-video8.MOV';



import React, { useRef, useState } from 'react';

function BackgroundVideo() {
  const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);

  const togglePlay = () => {

    const video = videoRef.current;
    if (video) {
      video.autoplay = true;
      video.loop = true;
      video.muted = true;
    }
  };


  const handleVolumeChange = (event) => {
    const volumeLevel = parseFloat(event.target.value);
    setVolume(volumeLevel);
    videoRef.current.volume = volumeLevel;
  };

  return (
    <div className='video'>
      <div className='videos'>
        <div className='video-style'>
          <div className='video-gap'>
            <video className='video-box' ref={videoRef}  autoPlay muted loop width>
              <source className="N-video" src={video1} type="video/mp4" />
            </video></div>
            <div className='video-gap'>
            <video className='video-box' ref={videoRef} autoPlay muted loop width>
              <source className="N-video" src={video3} type="video/mp4" />
            </video></div>
            {/* <div className='video-gap'>
            <video className='video-box' ref={videoRef} controls autoplay loop muted>
              <source className="N-video" src={video8} type="video/mp4" />
            </video></div> */}
            <div className='video-gap'>
            <video className='video-box' ref={videoRef} autoPlay muted loop width>
              <source className="N-video" src={video5} type="video/mp4" />
            </video></div>
            {/* <div className='video-gap'>
            <video className='video-box' ref={videoRef} controls autoplay loop muted>
              <source className="N-video" src={video7} type="video/mp4" />
            </video></div> */}
            <div className='video-gap'>
            <video className='video-box' ref={videoRef} autoPlay muted loop width>
              <source className="N-video" src={video4} type="video/mp4" />
            </video></div> 
        </div>

      </div>
      <div className='video-head'>
        {/* <h1>Forest Wellness</h1> */}
      </div>
    </div>
  );
}

export default BackgroundVideo;
