import React from "react";
import StarRating from "./startRating";
import './RetailProducts.css';
// import ashbottle from '../assects/ash-bottle.png';
import ashbottle from '../assects/ashfinal.jpg'

import allproimg from '../assects/6.jpg';
// import Whatweoffer from '../assects/whatweoffer.jpg';

import Jaggery from '../assects/Jaggery.png';
import Multiflora from '../assects/Multiflora.png';
import Honeyorg from '../assects/Honeyorg.png'
import Babool from '../assects/Babool.png';
import HerbalTea from '../assects/HERBAL-TEA.png';
import Mustard from '../assects/Mustard.png';
import Coriander from '../assects/Coriander.png';
import Pink from '../assects/PinkTea.jpeg';
import Trible from '../assects/Trible Tea.jpeg';
import Moring from '../assects/moringacap.jpg';
import GreenTea from '../assects/Green Tea.jpeg';
import Shilajit from '../assects/Shilajit.jpeg';
import SafedMusli from '../assects/Safedmusli.jpg'

import Giloy from '../assects/Untitled design/Giloy.png'
import Arjun from '../assects/Untitled design/Arjuna.png'
import Garlic from '../assects/Untitled design/Garlic.png'
import Gudmar from '../assects/Untitled design/Gudmar.png'
import Karela from '../assects/Untitled design/karela.png'
import Belgiri from '../assects/Untitled design/Belgiri.png'
import Shatavri from '../assects/Untitled design/Shitavri.png'


// import Slider from "react-slick"; 



const RetailProducts = () => {

    return (
        <div>
            <div className="pro-slider">
                <div className="product-img-slider">
                    <img src={allproimg} alt="" />
                    {/* <img src={Whatweoffer} alt="" /> */}
                    <img src="" alt="" />
                    <img src="" alt="" />
                </div>
            </div>

            <div className="all-products">

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Immunity And Detox</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={Giloy} alt="ashwagandha" />
                            <button><a>Giloy</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Honeyorg} alt="Honey" />
                            <button><a>Neem</a></button>
                            <li className="buybox"><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></li>
                        </div>


                        <div className="sub-box">
                            <img src={Moring} alt="Moringa Powder" />
                            <button><a>Daruhaldi </a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Jaggery} alt="" />
                            <button><a>Tulsi</a></button>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Heart And Circulation</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={Arjun} alt="ashwagandha" />
                            <button><a>Arjuna</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Garlic} alt="Moringa Powder" />
                            <button><a>Garlic </a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Weight Management And Metabolism</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={Karela} alt="ashwagandha" />
                            <button><a>Karela</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Gudmar} alt="Honey" />
                            <button><a>Gudmar</a></button>
                            <li className="buybox"><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></li>
                        </div>


                        <div className="sub-box">
                            <img src={Moring} alt="Moringa Powder" />
                            <button><a>Moringa </a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Moring} alt="Moringa Powder" />
                            <button><a>Sehna </a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Digestive Health</h2>
                    <div className="boxes">

                        <div className="sub-box">
                            <img src={Belgiri} alt="Honey" />
                            <button><a>Belgiri</a></button>
                            <li className="buybox"><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></li>
                        </div>


                        <div className="sub-box">
                            <img src={Moring} alt="Moringa Powder" />
                            <button><a>Punarvana </a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Brain And Nervous System</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={ashbottle} alt="ashwagandha" />
                            <button><a>Shankpushpi</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Honeyorg} alt="Honey" />
                            <button><a>Brahmi</a></button>
                            <li className="buybox"><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Strength And Muscle Growth</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={ashbottle} alt="ashwagandha" />
                            <button><a>Shilajit</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={SafedMusli} alt="Honey" />
                            <button><a>Safed Musli</a></button>
                            <li className="buybox"><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Sexual And Reproductive Health</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={Shatavri} alt="ashwagandha" />
                            <button><a>Shatavri</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>

                        <div className="sub-box">
                            <img src={Honeyorg} alt="Honey" />
                            <button><a>Kaunch Beej</a></button>
                            <li className="buybox"><a href="https://www.instagram.com/p/DFNR4nRS3Dh/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                <div className="all-pro" id="#stress-relief">
                    <h2 className="sub-title">Joint And Pain Relife</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={ashbottle} alt="ashwagandha" />
                            <button><a>Nirgundi</a></button>
                            <li className="buybox"><a href="https://amzn.in/d/1CSBXT6" >Buy Now</a></li>
                        </div>
                    </div>
                </div>

                
                <div className="all-pro">
                    <h2 className="sub-title"> Skin And Hair Health</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={HerbalTea} alt="Hearbal Tea" />
                            <button><a>Amla</a></button>
                        </div>
                    </div>
                </div>


            </div>

            <div className="reviews">
                <div>
                    <h1>Reviews</h1>
                    <div className="review-box">
                        <div className="rev">
                            <h2>Atharv04</h2>
                            <StarRating count={4} size="1x" color="#ffc107" />
                            <p>"I've been using Forest Wellness Ashwagandha for a month now, and the results are fantastic. I feel more relaxed and my stress levels have significantly decreased. The quality is outstanding, and I love that it's sourced from natural, sustainable forests."</p>
                        </div>

                        <div className="rev">
                            <h2>Tanish00</h2>
                            <StarRating count={3} size="1x" color="#ffc107" />
                            <p>"I recently switched to Forest Wellness Stevia, and it’s been a game-changer. It’s a natural, zero-calorie sweetener that doesn’t compromise on taste. Perfect for my coffee and desserts, and it feels good to use a product that’s sustainably harvested from nature."</p>
                        </div>

                        <div className="rev">
                            <h2>Ravi590</h2>
                            <StarRating count={5} size="1x" color="#ffc107" />
                            <p>"Forest Wellness Honey is simply the best. It’s pure, organic, and has a rich, delicious flavor. I use it every morning in my tea, and it’s also great for baking. Knowing it comes from pristine forest areas makes it even better."</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RetailProducts;

